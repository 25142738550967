import React, { Component } from "react"
import { graphql } from "gatsby"
import { sortReviewsByDate } from "../utils/utils"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FixedFacewallHomepage from "./index/FixedFacewallHomepage"
import StaticHeading from "./index/StaticHeading"
import Sizzle from "./index/Sizzle"
import ImageText from "./index/ImageText"
import AllRatingsReviews from "../components/SocialReviews/AllSocialReviews"
import FirstTime from "./index/FirstTime"
import InTheCommunity from "./index/InTheCommunity"
import LearnMoreCards from "./index/LearnMoreCards"
// import ImageTexts from "../components/ImageTexts"
import StaticHero from "./index/StaticHero"
import { TestimonialPreview } from "../components/Cards"
import { Image, Text } from "../components/Core"
import { Columns, Section } from "../components/Containers"
import TheaterVideo from "../components/Theater/TheaterVideo"
import TestimonialGrid from "../components/Cards/TestimonialGrid"
import { ProgramToast } from "../components/program-toast"

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      playing: false
    }
  }

  render() {
    const post = this.props.data.allUniquePagesJson.nodes[0]
    const { metaTitle, metaDescription, language } = this.props.pageContext

    const staticStyle = {
      position: "relative",
      overflow: "unset",
      width: "100%",
      zIndex: 1
    }
    const sizzleButtonStyle = {
      position: "absolute",
      top: 0,
      zIndex: 200
    }

    const hasReviews = this.props.data.hasReviews.nodes[0].reviews
    let allReviews = this.props.data.allReviews.nodes

    const sortedReviews = sortReviewsByDate(allReviews)

    // shuffleArray(allReviews);

    let fixedFacewallReviews = []
    if (sortedReviews.length >= 8) {
      fixedFacewallReviews = sortedReviews.slice(0, 8)
    } else {
      fixedFacewallReviews = sortedReviews.slice(0, 4)
    }

    let schemaData = null
    if (language === "en") {
      schemaData = {
        presets: post.schemas.presets,
        customSchema: post.schemas.custom,
        dateModified: this.props.pageContext.dateModified,
        language,
        metaTitle: metaTitle,
        description: metaDescription,
        path: this.props.location.pathname
      }
    }

    return (
      <Layout
        className={`main-homepage ${language}`}
        language={language}
        layoutClass="homepage-fab"
        noFab
        pageTitle="main-homepage">
        <SEO
          title={metaTitle}
          description={metaDescription}
          schemaData={schemaData}
          pathname={this.props.location.pathname}
          lang={language}
        />

        <div className="static-container" style={staticStyle}>
          {post.hasSizzle ? (
            <Sizzle
              playing={this.state.playing}
              sizzleButtonStyle={sizzleButtonStyle}
              youtube={post.sizzleYoutube}
              post={post}
              language={language}>
              <StaticHeading
                playing={this.state.playing}
                youtube={post.sizzleYoutube}
                heading={post.hero.heading}
                subheading={post.hero.subheading}
                language={language}
              />
            </Sizzle>
          ) : (
            <StaticHero
              post={post}
              heading={post.hero.heading}
              subheading={post.hero.subheading}
              bgImage={post.hero.bgImage}
              language={language}
            />
          )}

          {language == "en" && (
            <>
              {hasReviews && (
                <TestimonialGrid language={language} reviews={allReviews} />
              )}

              {hasReviews && post.featuredReviews.length > 1 && (
                <div className="columns">
                  {post.featuredReviews.map(card => (
                    <div key={card.youtube} className="column">
                      <TestimonialPreview
                        language={language}
                        youtube={card.youtube}
                        image={card.image}
                        heading={card.heading}
                        subheading={card.subHeading}
                      />
                    </div>
                  ))}
                </div>
              )}
            </>
          )}

          <LearnMoreCards language={language} post={post.learnMoreCards} />

          <ImageText
            // hasButtons
            language={language}
            post={post}
            textImage={post.homeSectionOne.imageId}
            noReverse
            colorBack={language === "es"}
          />

          {language === "es" && (
            <Section>
              <FixedFacewallHomepage
                language={language}
                heading={
                  language === "es"
                    ? "Escuche de Nuestros Pacientes"
                    : "Hear From Our Patients"
                }
                reviews={fixedFacewallReviews}
              />
            </Section>
          )}

          {post.about.hasThisSection && (
            <Section colorBack>
              <Columns sideColumnsSize={4}>
                <div className="column px-40--mobile">
                  <Text
                    className="has-text-centered mb-2"
                    as="h2"
                    text={post.about.heading}
                  />
                  <Text text={post.about.text} />
                </div>
              </Columns>
            </Section>
          )}

          {post.inTheCommunity.hasThisSection && (
            <Section colorBack>
              <InTheCommunity
                heading={post.inTheCommunity.heading}
                blurb={post.inTheCommunity.text}
                buttons={post.inTheCommunity.buttons}
                image={post.inTheCommunity.image}
              />
            </Section>
          )}
          {/* <HeadingVideo post={post} /> */}

          <FirstTime
            //colorBack
            language={language}
            post={post}
            noReverse
          />

          {post.faqs && (
            <Section colorBack>
              <Columns sideColumnsSize={4}>
                <div className="column px-40--mobile">
                  <Text text={post.faqs} />
                </div>
              </Columns>
            </Section>
          )}

          {post.homeRatingsReviews.hasThisSection && (
            <Section>
              <AllRatingsReviews
                animation
                heading={post.homeRatingsReviews.heading}
                blurb={post.homeRatingsReviews.blurb}
                language={language}
                buttonHref={post.homeRatingsReviews.buttonOne.href}
                buttonText={post.homeRatingsReviews.buttonOne.buttonText}
              />
            </Section>
          )}
        </div>
        {/* <ProgramToast
          href="/secondchance/"
          endDate="October 6 2023 11:59 PM"
          program="second chance"
        /> */}
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query reviewsIndexQuery($title: String!, $language: String!) {
    allReviews: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, language: { eq: $language } }
      limit: 30
    ) {
      nodes {
        ...FacewallData
      }
    }
    hasReviews: allDataJson(filter: { optionsItem: { eq: "build-pages" } }) {
      nodes {
        reviews
      }
    }
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        schemas {
          presets
          custom
        }
        hasSizzle
        hero {
          heading
          subheading
          bgImage
        }
        featuredReviews {
          heading
          subHeading
          youtube
          image
        }
        homeSectionOne {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
          imageId
          text
        }
        about {
          hasThisSection
          heading
          text
        }
        learnMoreCards {
          blurb
          hasThisSection
          heading
          cards {
            blurb
            heading
            imageId
            button {
              buttonText
              href
            }
          }
        }
        homeRatingsReviews {
          hasThisSection
          heading
          # blurb
          buttonOne {
            buttonText
            href
          }
        }
        faqs
        inTheCommunity {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          leftRight
          subheading
          text
        }
        firstTimePatient {
          buttons {
            button {
              href
              buttonText
              destination
              appearance
            }
          }
          heading
          text
        }
        metaTitle
        sizzleYoutube
        metaDescription
        title
      }
    }
  }
`

export default IndexPage
