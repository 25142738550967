import React from "react"
import { Text, Image } from "../Core"
import TheaterVideo from "../Theater/TheaterVideo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlayCircle } from "@fortawesome/pro-regular-svg-icons/faPlayCircle"

const TestimonialPreview = ({
  image,
  youtube,
  heading,
  subheading,
  reviewerName,
  city,
  procedure,
  withHoverCard,
  hoverLeft,
  language
}) => {
  return (
    <TheaterVideo
      videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
      overVideo
      asWrapper
      language={language}
      wrapperClassName="tp__wrapper"
      childrenWrapperClassName="tp__theater"
      buttonClass="contained">
      <div className={`tp ${heading ? "featured" : ""}`}>
        <Image
          className="tp__img block"
          quality={withHoverCard ? "30" : "90"}
          publicId={image}
        />

        {!withHoverCard ? (
          <div className="tp__card watch-cover">
            <FontAwesomeIcon icon={faPlayCircle} />
            <div>
              <Text className="mt-0 mb-0" as="h5" text={heading} />
              <Text className="mt-0 mb-0" as="p" text={subheading} />
            </div>
          </div>
        ) : (
          <div className="tp__card icon-only">
            <FontAwesomeIcon icon={faPlayCircle} />
          </div>
        )}

        {withHoverCard && (
          <div className={`tp__hover-card ${hoverLeft ? "left" : "right"}`}>
            <Text as="h5" text={reviewerName} />
            <Text as="p" text={city} />
            <Text as="p" text={procedure} />
          </div>
        )}
      </div>
    </TheaterVideo>
  )
}

export default TestimonialPreview
